import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {getCompanies, getCompanyTitles} from "../services/CompanyService";
import {manualNews} from "../services/NewsService";
import {useMessageContext} from "../contexts/MessageContext";
import Loader from "./Loader";
import {manualArticle} from "../services/ArticleService";
import {manualBrandingBanner} from "../services/BrandingBannerService";
import {getImages, getImageTitles} from "../services/ImageTemplateService";
import {TitleFormats} from "../text/TitleFormats";
import {getBankImages} from "../services/ImageBankService";

const FeedOnDemand = ({open, handleClose}) => {

  const [companies, setCompanies] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState();

  const [imageIds, setImageIds] = useState([]);

  const [titlesIds, setTitlesIds] = useState([]);

  const [articleImageIds, setArticleImageIds] = useState([]);

  const [articleTitleIds, setArticleTitleIds] = useState([]);

  const [imageBankIds, setImageBankIds] = useState([]);

  const [selectedImageBankId, setSelectedImageBankId] = useState([]);

  const [selectedImageId, setSelectedImageId] = useState();

  const [selectedArticleImageId, setSelectedArticleImageId] = useState();

  const [selectedFormat, setSelectedFormat] = useState();

  const [selectedTitleId, setSelectedTitleId] = useState();

  const [selectedTaglineId, setSelectedTaglineId] = useState();

  const [type, setType] = useState("NEWS");

  const [loading, setLaoding] = useState(false);

  const {notificationHandler} = useMessageContext();

  const [companyTitles, setCompanyTitles] = useState([]);

  const [selectedCompanyTitle, setSelectedCompanyTitle] = useState();

  useEffect(() => {
    getCompanies()
        .then(res => setCompanies(res.data))
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      getCompanyTitles(selectedCompany?.id, selectedFormat)
          .then(res => setCompanyTitles(res.data))
    }
  }, [selectedFormat])

  useEffect(() => {
    if (selectedCompany) {
      getImages(selectedCompany?.id, "BANNER")
          .then(res => setImageIds(res.data))

      getImageTitles(selectedCompany?.id, "BANNER", true)
          .then(res => setTitlesIds(res.data.filter(title => title.status === "AVAILABLE")))

      getImages(selectedCompany?.id, "ARTICLE")
          .then(res => setArticleImageIds(res.data))

      getImageTitles(selectedCompany?.id, "ARTICLE", true)
          .then(res => setArticleTitleIds(res.data.filter(title => title.status === "AVAILABLE")))

      getBankImages(selectedCompany?.id, "SINGLE_POST")
          .then(res => setImageBankIds(res.data));
    }
  }, [selectedCompany]);


  return (
      <>
        <Loader loading={loading}/>
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              onSubmit: (event) => {
                setLaoding(true);
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());

                if (type === "ARTICLE") {
                  manualArticle(selectedCompany.id, selectedCompanyTitle, selectedArticleImageId?.id, selectedTaglineId?.id, null, type, selectedFormat)
                      .then(handleClose)
                      .then(() => notificationHandler.success('Manual Article triggered'))
                      .finally(() => setLaoding(false))
                } else if (type === "SINGLE_POST") {
                  manualArticle(selectedCompany.id, selectedCompanyTitle, null, null, selectedImageBankId?.id, type, selectedFormat)
                      .then(handleClose)
                      .then(() => notificationHandler.success('Manual Single Post triggered'))
                      .finally(() => setLaoding(false))
                } else if (type === "NEWS" || type === "WEEKLY_DIGEST") {
                  manualNews(selectedCompany.id, formJson.days, formJson.max, type)
                      .then(handleClose)
                      .then(() => notificationHandler.success('Manual News triggered'))
                      .finally(() => setLaoding(false))
                } else if (type === "BRANDING_BANNER") {
                  manualBrandingBanner(selectedCompany.id, selectedImageId.id, selectedTitleId.id)
                      .then(handleClose)
                      .then(() => notificationHandler.success('Manual Branding Banner triggered'))
                      .finally(() => setLaoding(false))
              }
            }}}
        >
          <DialogContent sx={{width: "550px"}}>
            <DialogTitle align="center">Manual Trigger</DialogTitle>
            <Box sx={{mt: "24px", display: "flex", justifyContent: "space-between"}}>
            <FormControl sx={{width: "45%"}}>
              <InputLabel id="demo-simple-select-label">Company</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Company"
                  variant={"standard"}
                  value={selectedCompany}
                  onChange={(e) => setSelectedCompany(e.target.value)}
              >

                {companies.map((company, i) => {
                  return <MenuItem key={i} value={company}>{company.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl sx={{width: "45%", mt: "16px"}}>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  variant={"standard"}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
              >

                <MenuItem value="NEWS">News</MenuItem>
                <MenuItem value="WEEKLY_DIGEST">Weekly Digest</MenuItem>
                <MenuItem value="ARTICLE">Article</MenuItem>
                <MenuItem value="SINGLE_POST">Single Post</MenuItem>
                <MenuItem value="BRANDING_BANNER">Branding Banner</MenuItem>
              </Select>
            </FormControl>
            </Box>
            {type === "NEWS" &&
                <>
                  <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <TextField
                autoFocus
                sx={{width: "45%", mt: "16px"}}
                margin="dense"
                defaultValue={5}
                id="max"
                name="max"
                label="Max results"
                type="number"
                variant="standard"
            />
            <TextField
                autoFocus
                defaultValue={5}
                sx={{width: "45%", mt: "16px"}}
                margin="dense"
                id="days"
                name="days"
                label="Number Of Days"
                type="number"
                variant="standard"
            />
                  </Box>
                  </>}
            {(type === "ARTICLE" || type === "SINGLE_POST") && <>
              <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <FormControl sx={{width: "45%", mt: "16px"}}>
                  <InputLabel id="demo-simple-select-label">Format</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Format"
                      variant={"standard"}
                      value={selectedFormat}
                      onChange={(e) => setSelectedFormat(e.target.value)}
                  >

                    {Object.entries(TitleFormats).map((format, i) => {
                      return <MenuItem key={i} value={format[0]}>
                        {format[1]}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{width: "45%", mt: "16px"}}>
                  <InputLabel id="demo-simple-select-label">Title</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Title"
                      variant={"standard"}
                      value={selectedCompanyTitle}
                      onChange={(e) => setSelectedCompanyTitle(e.target.value)}
                  >

                    {companyTitles.map((title, i) => {
                      return <MenuItem key={i} value={title.id}>
                        {title.title}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Box>
              {type === "ARTICLE" && <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <FormControl sx={{width: "45%", mt: "16px"}}>
                    <InputLabel id="demo-simple-select-label">Image</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="image"
                        variant={"standard"}
                        value={selectedArticleImageId}
                        onChange={(e) => setSelectedArticleImageId(e.target.value)}
                    >

                      {articleImageIds.map((image, i) => {
                        return <MenuItem sx={{display: "flex", justifyContent: "center"}} key={i} value={image}>
                          <Box
                              component="img"
                              sx={{
                                height: 100,
                                width: 100,
                              }}
                              src={image.imageUrl}
                          /></MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{width: "45%", mt: "16px"}}>
                    <InputLabel id="demo-simple-select-label">Tagline</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="tagline"
                        variant={"standard"}
                        value={selectedTaglineId}
                        onChange={(e) => setSelectedTaglineId(e.target.value)}
                    >

                      {articleTitleIds.map((title, i) => {
                        return <MenuItem key={i} value={title}>{title.title}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Box>}
              {type === "SINGLE_POST" && <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <FormControl sx={{width: "45%", mt: "16px"}}>
                  <InputLabel id="demo-simple-select-label">Image</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="image"
                      variant={"standard"}
                      value={selectedImageBankId}
                      onChange={(e) => setSelectedImageBankId(e.target.value)}
                  >

                    {imageBankIds.map((image, i) => {
                      return <MenuItem sx={{display: "flex", justifyContent: "center"}} key={i} value={image}>
                        <Box
                            component="img"
                            sx={{
                              height: 100,
                              width: 100,
                            }}
                            src={image.imageUrl}
                        /></MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Box>}
            </>}
            {type === "BRANDING_BANNER" && <>
              <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <FormControl sx={{width: "45%", mt: "16px"}}>
                <InputLabel id="demo-simple-select-label">Image</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="image"
                    variant={"standard"}
                    value={selectedImageId}
                    onChange={(e) => setSelectedImageId(e.target.value)}
                >

                  {imageIds.map((image, i) => {
                    return <MenuItem sx={{display: "flex", justifyContent: "center"}} key={i} value={image}>
                      <Box
                        component="img"
                        sx={{
                          height: 100,
                          width: 100,
                        }}
                        src={image.imageUrl}
                    /></MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{width: "45%", mt: "16px"}}>
                <InputLabel id="demo-simple-select-label">Banner copy</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Banner copy"
                    variant={"standard"}
                    value={selectedTitleId}
                    onChange={(e) => setSelectedTitleId(e.target.value)}
                >

                  {titlesIds.map((title, i) => {
                    return <MenuItem key={i} value={title}>{title.title}</MenuItem>
                  })}
                </Select>
              </FormControl>
              </Box>
            </>}
          </DialogContent>
          <DialogActions>
            <Button color={"error"} variant={"text"} onClick={handleClose}>close</Button>
            <Button disabled={selectedCompany == null} variant={"contained"} type={"submit"}>generate</Button>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default FeedOnDemand;
