import {useEffect, useState} from "react";
import {getImages, getImageTitles} from "../../services/ImageTemplateService";
import {getBankImages} from "../../services/ImageBankService";
import {
  Box, Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import {updateArticleImage} from "../../services/ArticleService";
import Loader from "../Loader";

const SelectNewImageDialog = ({setReload, notificationHandler, articleId, companyId, open, handleClose, isArticle}) => {

  const [imageBankIds, setImageBankIds] = useState([]);

  const [selectedImageBankId, setSelectedImageBankId] = useState([]);

  const [articleImageIds, setArticleImageIds] = useState([]);

  const [articleTitleIds, setArticleTitleIds] = useState([]);

  const [selectedImageId, setSelectedImageId] = useState();

  const [selectedTaglineId, setSelectedTaglineId] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyId) {
      getImages(companyId, "ARTICLE")
          .then(res => setArticleImageIds(res.data))

      getBankImages(companyId, "SINGLE_POST")
          .then(res => setImageBankIds(res.data));

      getImageTitles(companyId, "ARTICLE", true)
          .then(res => setArticleTitleIds(res.data.filter(title => title.status === "AVAILABLE")))

    }
  }, [companyId]);


  const handleSave = () => {
    setLoading(true);
    updateArticleImage(articleId, isArticle ? selectedImageId?.id : selectedImageBankId.id, selectedTaglineId?.id)
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success('Image updated'))
        .catch(() => notificationHandler.error('Failed to update image'))
        .finally(handleClose)
        .finally(() => setLoading(false))

  }

  const articleImage = () => {
    return <Box sx={{display: "flex", justifyContent: "space-between"}}>
      <FormControl sx={{width: "45%", mt: "16px"}}>
        <InputLabel id="demo-simple-select-label">Image</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="image"
            variant={"standard"}
            value={selectedImageId}
            onChange={(e) => setSelectedImageId(e.target.value)}
        >

          {articleImageIds.map((image, i) => {
            return <MenuItem sx={{display: "flex", justifyContent: "center"}} key={i} value={image}>
              <Box
                  component="img"
                  sx={{
                    height: 100,
                    width: 100,
                  }}
                  src={image.imageUrl}
              /></MenuItem>
          })}
        </Select>
      </FormControl>
      <FormControl sx={{width: "45%", mt: "16px"}}>
        <InputLabel id="demo-simple-select-label">Tagline</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="tagline"
            variant={"standard"}
            value={selectedTaglineId}
            onChange={(e) => setSelectedTaglineId(e.target.value)}
        >

          {articleTitleIds.map((title, i) => {
            return <MenuItem key={i} value={title}>{title.title}</MenuItem>
          })}
        </Select>
      </FormControl>
    </Box>
  }

  return (
      <>
        <Loader loading={loading}/>
      <Dialog
          open={open}
          onClose={handleClose}>
        <DialogContent sx={{width: "550px"}}>
          <DialogTitle align="center">Add Image</DialogTitle>
            {isArticle ? articleImage() : <>
              <Box sx={{display: "flex", justifyContent: "center"}}>
                <FormControl sx={{mt: "16px"}}>
                  <InputLabel id="demo-simple-select-label">Image</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="image"
                      sx={{width: "250px"}}
                      variant={"standard"}
                      value={selectedImageBankId}
                      onChange={(e) => setSelectedImageBankId(e.target.value)}
                  >

                    {imageBankIds.map((image, i) => {
                      return <MenuItem sx={{display: "flex", justifyContent: "center"}} key={i} value={image}>
                        <Box
                            component="img"
                            sx={{
                              height: 100,
                              width: 100,
                            }}
                            src={image.imageUrl}
                        /></MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Box>
            </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={"error"}>Cancel</Button>
          <Button onClick={handleSave} variant={"contained"}>Save</Button>
        </DialogActions>
      </Dialog>
      </>
  );

};

export default SelectNewImageDialog;
