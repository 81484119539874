import {Box, Fab, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {useEffect, useRef, useState} from "react";
import {deleteImage, getImages, uploadImages} from "../services/ImageTemplateService";
import Loader from "./Loader";
import AddEditImage from "./AddEditImage";

const ImageTemplates = ({companySettings, company, type}) => {

  const [images, setImages] = useState([]);

  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setLoading(true);
    getImages(company.id, type)
        .then(res => setImages(res.data))
        .then(() => setLoading(false))
  }, [reload]);


  const imageSelected = (img) => {
    setSelectedImage(img);
    setOpen(true);
  }
  const handleDelete =(id) => {
    deleteImage(id)
        .then(() => setReload(prv => !prv));
  }

  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false)
  }

  const onSave = (imageTemplate) => {
    setLoading(true)
    let imageBase64 = imageTemplate.imageTemplate.imageBase64
    let formJson = imageTemplate.formJson;
    uploadImages({
      id: formJson.id,
      imageBase64: imageBase64,
      name: formJson.name,
      fontSize: formJson.fontSize,
      x: formJson.x,
      y: formJson.y,
      maxWidth: formJson.maxWidth,
      maxHeight: formJson.maxHeight,
      color: formJson.color,
      font: imageTemplate.selectedFont,
      type: type,
      companyId: company.id
    })
        .then(() => setReload(prv => !prv))
        .then(handleClose)
  }

  return (
      <Box>
        <Loader loading={loading}/>
        <AddEditImage defaults={type === "BANNER" ? companySettings.bannerDefaults : companySettings.articleDefaults} company={company} onSubmit={onSave} selectedImage={selectedImage} open={open} handleClose={handleClose} title={"Image"}  />
      <Table>
        <TableHead>
          <TableRow>
          <TableCell>
            Image
          </TableCell>
            <TableCell>
              Name
            </TableCell>
          <TableCell align="right">
            Actions
          </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {images.map(img => {
            return <TableRow sx={{cursor: "pointer"}} onClick={() => imageSelected(img)}>
              <TableCell>
                <Box
                    component="img"
                    sx={{
                      height: type === "BANNER" ? 150 : 200,
                      width: type === "BANNER" ? 150 : 400,
                    }}
                    alt="The house from the offer."
                    src={img.imageUrl}
                />
              </TableCell>
              <TableCell>
                {img.name}
              </TableCell>
              <TableCell align="right">
                <Box sx={{cursor: "pointer"}} onClick={() => handleDelete(img.id)}>
                  <DeleteIcon/>
                </Box>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
        <Box sx={{mt: "24px", display: "flex", justifyContent: "end"}}>
            <Fab color="primary" aria-label="add" onClick={() => setOpen(true)}>
              <AddIcon />
            </Fab>
        </Box>
      </Box>
  )

};

export default ImageTemplates;
