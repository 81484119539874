import {Box, Button, Paper, TextField, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useMessageContext} from "../contexts/MessageContext";
import {useState} from "react";
import {publishNews, saveNews} from "../services/NewsService";

const FeedSummery = ({element, i, newsId, companyId, integration}) => {
  const [editMode, setEditMode] = useState({
    summery: element.summery
  });

  const {notificationHandler} = useMessageContext();


  const handleOnSave = () => {
    saveNews({
      ...element, summery: editMode.summery
    }).then(r => element.summery = editMode.summery)
        .then(() => notificationHandler.success('Updated successfully'))
        .then(() => handleEditMode(element))

  }

  const handleOnPost = () => {
    publishNews(newsId, element.id, companyId)
        .then(() => notificationHandler.success('Published successfully'))
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => notificationHandler.success('Text copied'))
  }

  const handleEditMode = (element) => {
    setEditMode(() => {
      return {
        summery: element.summery
      }
    });
  }

  const updateSummery = (value) => {
    setEditMode(prv => {
      return {...prv, summery : value}
    })
  }

  return <Paper sx={{padding: "24px", mb: "12px"}} key={i} elevation={2}>
    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <Typography sx ={{fontSize: "24px"}}>Summary:</Typography>
      <Box >
        <ContentCopyIcon sx={{cursor: "pointer"}} color={"primary"} onClick={() => copyToClipboard(element.summery)} />
        {/*<EditIcon sx={{cursor: "pointer"}} color={"primary"} onClick={() => handleEditMode(element)}/>*/}
      </Box>
    </Box>
        <TextField
            id="outlined-textarea"
            sx={{width: "100%"}}
            value={editMode.summery}
            onChange={(e) => updateSummery(e.target.value)}
            multiline
        />
          <Box sx={{display: "flex", justifyContent: "right"}}>
            <Button variant={"contained"} sx={{mt: "12px"}} onClick={() => handleOnSave()}>Save</Button>
            <Button
                disabled={!integration?.some(i => i.type === "LINKEDIN" && i.status === "CONNECTED")}
                variant={"text"} sx={{mt: "12px"}} onClick={() => handleOnPost()}>Publish</Button>
          </Box>
  </Paper>
};

export default FeedSummery;
