import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel, MenuItem, Select,
  TextField
} from "@mui/material";
import {useRef, useState} from "react";

const AddEditImage = ({defaults, company, selectedImage, title, open, handleClose, onSubmit}) => {

  const inputFileRef = useRef( null );

  const [imageTemplate, setImageTemplate] = useState({});

  const [selectedFont, setSelectedFont] = useState(selectedImage?.font || defaults?.font);

  const fonts = ["Inter-Bold",
    "Inter-Regular",
    "Helvetica-Bold",
    "Helvetica-Regular",
    "Lato-Bold",
    "Lato-Regular",
    "Montserrat-Bold",
    "Montserrat-Regular",
    "OpenSans-Bold",
    "OpenSans-Regular",
    "Poppins-Bold",
    "Poppins-Regular",
    "Roboto-Bold",
    "Roboto-Regular",
    "SourceSerif4-Bold",
    "SourceSerif4-Regular",
    "Ubuntu-Bold",
    "Ubuntu-Regular",
    "Manrope-Bold",
    "Manrope-Regular",]

  const onClose = () => {
    setImageTemplate({});
    handleClose();
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onFileChange = async ( e ) => {
    /*Selected files data can be collected here.*/
    let body = await Promise.all([...e.target.files].map(async file => {
      return {
        name: file.name,
        imageBase64: await convertBase64(file)
      }
    }));

    setImageTemplate(body[0]);
  }
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  }


  return (<Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          formJson["id"] = selectedImage?.id;
          onSubmit({formJson, imageTemplate, selectedFont});
        },
      }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <TextField
          autoFocus
          required
          defaultValue={selectedImage?.name || ""}
          sx={{width: "250px"}}
          margin="dense"
          id="name"
          name="name"
          label="name"
          type="text"
          variant="standard"
      />
      <TextField
          autoFocus
          required
          defaultValue={selectedImage?.fontSize || defaults?.fontSize}
          sx={{ml: "24px", width: "250px"}}
          margin="dense"
          id="fontSize"
          name="fontSize"
          label="font Size"
          type="number"
          variant="standard"
      />
    </DialogContent>
    <DialogContent>
      <TextField
          autoFocus
          required
          defaultValue={selectedImage?.color || defaults?.color}
          sx={{width: "250px"}}
          margin="dense"
          id="color"
          name="color"
          label="color"
          type="text"
          variant="standard"
      />
      <Select
          sx={{ml: "24px", width: "250px"}}
          fullWidth
          labelId="demo-simple-select-label"
          id="font"
          value={selectedFont}
          onChange={(e) => setSelectedFont(e.target.value)}
      >

        {fonts.map((font, i) => {
          return <MenuItem value={font}>{font}</MenuItem>
        })}
      </Select>
    </DialogContent>
    <DialogContent>
      <TextField
          autoFocus
          required
          defaultValue={selectedImage?.x || defaults?.x}
          sx={{width: "250px"}}
          margin="dense"
          id="x"
          name="x"
          label="x"
          type="number"
          variant="standard"
      />
      <TextField
          autoFocus
          required
          defaultValue={selectedImage?.y || defaults?.y}
          sx={{ml: "24px", width: "250px"}}
          margin="dense"
          id="y"
          name="y"
          label="y"
          type="number"
          variant="standard"
      />
    </DialogContent>
    <DialogContent>
      <TextField
          autoFocus
          required
          defaultValue={selectedImage?.maxWidth || defaults?.maxWidth}
          sx={{width: "250px"}}
          margin="dense"
          id="maxWidth"
          name="maxWidth"
          label="maxWidth"
          type="number"
          variant="standard"
      />
      <TextField
          autoFocus
          required
          defaultValue={selectedImage?.maxHeight || defaults?.maxHeight}
          sx={{ml: "24px", width: "250px"}}
          margin="dense"
          id="maxHeight"
          name="maxHeight"
          label="maxHeight"
          type="number"
          variant="standard"
      />
    </DialogContent>
    <DialogContent>
      {(imageTemplate.imageBase64 || selectedImage?.imageUrl) &&
      <Box
          component="img"
          sx={{
            height: 150,
            width: 150,
          }}
          src={imageTemplate.imageBase64 || selectedImage?.imageUrl}
      />}
    </DialogContent>
    <DialogContent>
      <Button
          variant="contained"
          component="label"
      >
        Upload Image
        <input
            type="file"
            hidden
            ref={inputFileRef}
            onChange={onFileChange}
        />
      </Button>
    </DialogContent>
    <DialogActions>
      <Button color={"error"} onClick={onClose}>Cancel</Button>
      <Button variant={"contained"} type="submit">Save</Button>
    </DialogActions>
  </Dialog>);
};

export default AddEditImage;
