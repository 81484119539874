import {Box, Button} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {publishBanner} from "../../services/BrandingBannerService";

const BannerPreview = ({data, notificationHandler, integration}) => {

  const handlePublish = () => {
    publishBanner(data.id)
        .then(() => notificationHandler.success('Published successfully'))
        .catch(() => notificationHandler.error('Failed to publish'))
  };

  const download = image => {
    fetch(image, {
      method: "GET",
      mode: 'cors',           // Ensures CORS is handled
      cache: 'no-cache',      // Prevents caching issues
      headers: {'Access-Control-Request-Headers': '*', 'Vary': 'Origin',},
    })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "image.png"); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });
  };

  return (
      <>
      <Box sx={{cursor: "pointer", display: "flex", justifyContent: "end"}}>
        <Box sx={{cursor: "pointer"}} onClick={() => {navigator.clipboard.writeText(data.imageUrl).then(() => notificationHandler.success('Image URL copied'))}} >
          <ContentCopyIcon/>
        </Box>
        <Box onClick={() => download(data.imageUrl)} sx={{cursor: "pointer", ml: "12px"}}>
          <CloudDownloadIcon/>
        </Box>
      </Box>

    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", mt: "20px"}}>
      <Box
          component="img"
          sx={{
            height: 350,
            width: 350,
          }}
          alt="The house from the offer."
          src={data.imageUrl}
      />
    </Box>
        <Box sx={{display: "flex", justifyContent: "right"}}>
          <Button
              disabled={!integration?.some(i => i.status === "CONNECTED" && i.type === "LINKEDIN")}
              variant={"contained"} sx={{mt: "12px"}} onClick={handlePublish}>Publish</Button>
        </Box>
      </>
  );
};

export default BannerPreview;
